import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from 'src/app/app-services/helper.service';
import {CrudService} from '../../../app-services/crud.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-profile-detail',
  templateUrl: './profile-detail.component.html',
  styleUrls: ['./profile-detail.component.scss']
})
export class ProfileDetailComponent implements OnInit {
  public managerName: string;     // name of manager
  public managerDetail: any;     // detail of manager
  public managerList: Array<any>; // get managerlist from helper service
  public listofOthermanagers: Array<any>; // list of rest manager
  constructor(private route: ActivatedRoute, private helperService: HelperService, private crudService: CrudService) {
    this.route.queryParams.subscribe(params => {
      this.managerName = params.name ? params.name : null;
      this.managerDetail = this.getDefaultManagementValues();
      // this.showSelectedManager(this.managerName);
      this.getManagementList();
    });
  }

  // returns default management values
  private getDefaultManagementValues(): any {
    return {
      status: null,
      createdAt: null,
      _id: null,
      name: null,
      designation: null,
      shortDescription: null,
      fullDescription: null,
      imageId: null,
      imageUrl: null,
      orderBy: null,
      updatedAt: null
    };
  }

  ngOnInit() {
    this.helperService.scrollTop();
    this.helperService.manageMentList.subscribe(list => {
      if (list) {
        this.managerList = list;
        this.listofOthermanagers = list.filter(val => val.name !== this.managerName);
        const index = this.listofOthermanagers.findIndex(listItem => listItem.name === this.managerName);
        this.managerDetail = this.managerList.find(listItem => listItem.name === this.managerName);
      }
    });
  }

  // used for get list of all management
  public getManagementList(): void {
    this.crudService.getData('web/managenent').subscribe((res: any) => {
      if (res.response_code === 200) {
        const managementList = _.orderBy(res.response_data.data, ['orderBy'], ['asc']);
        this.helperService.emitManagementList(managementList);
      } else {
        this.getManagementListFromLocalJson();
      }
    }, error => {
      this.getManagementListFromLocalJson();
    });
  }

  // get management list from local json
  public getManagementListFromLocalJson(): void {
    this.crudService.getLocalJSONData('management.json').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.helperService.emitManagementList(res.response_data.data);
      }
    });
  }

  // used for selected manager detail
  private showSelectedManager(managerName): void {
    this.managerName = managerName;
    console.log(`this.managerName ${this.managerName}`);
    this.listofOthermanagers = this.managerList.filter(val => val.name !== this.managerName);
    const index = this.listofOthermanagers.findIndex(listItem => listItem.name === this.managerName);
    this.managerDetail = this.managerList.find(listItem => listItem.name === this.managerName);
  }

}
