import {Component, OnInit} from '@angular/core';
import {PagesListModel} from '../models/page-info.model';
import {HelperService} from '../../app-services/helper.service';
import {CrudService} from 'src/app/app-services/crud.service';
import {VanancyModel} from '../models/vanancy.model';

@Component({
  selector: 'app-vacancies',
  templateUrl: './vacancies.component.html',
  styleUrls: ['./vacancies.component.scss']
})
export class VacanciesComponent implements OnInit {
  public pageInfo: PagesListModel;         // contains information about the page
  public vacancyList: Array<VanancyModel> = [];          // contains list of songs

  constructor(private helpService: HelperService, private crudService: CrudService, private helperService: HelperService) {
    this.getvacancyList();
  }

  ngOnInit() {
    this.helpService.scrollTop();
    this.helpService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Vacancies page');
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpService.getPageInformation('Vacancies page');
  }


  // get list of jobs
  private getvacancyList(): void {
    this.crudService.getData('vacancy/web').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.vacancyList = res.response_data;
        const currentTimeStamp = new Date().getTime();
        this.vacancyList = this.vacancyList.filter(list => list.expiryDate >= currentTimeStamp);
        this.helperService.emitVacancyList(this.vacancyList);
      } else {
        this.getVacancyListFromLocalJson();
      }
    }, error => {
      this.getVacancyListFromLocalJson();
    });
  }

  // gets list of jobs
  public getVacancyListFromLocalJson(): void {
    this.crudService.getLocalJSONData('vacancies.json').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.vacancyList = res.response_data;
        console.log(this.vacancyList);
        this.helperService.emitVacancyList(this.vacancyList);
      }
    });
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }

}
