import {Component, Input, OnInit} from '@angular/core';
import {PagesListModel} from '../../models/page-info.model';
import {HelperService} from '../../../app-services/helper.service';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})

export class VisionComponent implements OnInit {
  public pageInfo: PagesListModel;        // contains information about the page

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
    this.helpService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Vision, Mission and Value');
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpService.getPageInformation('Vision, Mission and Value');
  }

}
