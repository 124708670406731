import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sanitizeUrl'
})
export class FormatUrlPipe implements PipeTransform {
  constructor(private domSanitizer: DomSanitizer) {

  }

  transform(value: string): SafeResourceUrl {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
