import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../app-services/helper.service';

@Component({
  selector: 'app-media-center',
  templateUrl: './media-center.component.html',
  styleUrls: ['./media-center.component.scss']
})
export class MediaCenterComponent implements OnInit {

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
  }

}
