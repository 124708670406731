import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../../app-services/helper.service';

@Component({
  selector: 'app-product-international',
  templateUrl: './product-international.component.html',
  styleUrls: ['./product-international.component.scss']
})
export class ProductInternationalComponent implements OnInit {

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
  }

}
