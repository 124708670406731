import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {OwlModule} from 'ngx-owl-carousel';
// import AOS from 'aos';

import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatButtonModule} from '@angular/material/button';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatSelectModule} from '@angular/material/select';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {HeaderComponent} from './layout/header/header.component';
import {FooterComponent} from './layout/footer/footer.component';

import {HomeComponent} from './pages/home/home.component';

import {FormatUrlPipe} from './pages/home/shared/sanitizer.pipe';
import {ProfileComponent} from './pages/about/profile/profile.component';
import {VisionComponent} from './pages/about/vision/vision.component';
import {ManagementComponent} from './pages/about/management/management.component';
import {TerritoriesComponent} from './pages/about/territories/territories.component';
import {ProfileDetailComponent} from './pages/about/profile-detail/profile-detail.component';

import {ProductsComponent} from './pages/products/products.component';
import {IDDPacksComponent} from './pages/idd-packs/idd-packs.component';
import {BigTimeDataComponent} from './pages/big-time-data/big-time-data.component';
import {CareersComponent} from './pages/careers/careers.component';
import {VacanciesComponent} from './pages/vacancies/vacancies.component';
import {VacancyDetailComponent} from './pages/vacancy-detail/vacancy-detail.component';
import {SendMoneyComponent} from './pages/airteltigo-money/send-money/send-money.component';
import {MakePaymentsComponent} from './pages/airteltigo-money/make-payments/make-payments.component';
import {BuyProductsComponent} from './pages/airteltigo-money/buy-products/buy-products.component';
import {BankServicesComponent} from './pages/airteltigo-money/bank-services/bank-services.component';
import {BusinessHomeComponent} from './pages/business/business-home/business-home.component';
import {FixedServicesComponent} from './pages/business/fixed-services/fixed-services.component';
import {DedicatedInternetComponent} from './pages/business/dedicated-internet/dedicated-internet.component';
import {MobileServicesComponent} from './pages/business/mobile-services/mobile-services.component';
import {FlexiServicesComponent} from './pages/business/flexi-services/flexi-services.component';
import {ValueAddedServicesComponent} from './pages/business/value-added-services/value-added-services.component';
import {TopUpRechargeComponent} from './pages/personal/top-up-recharge/top-up-recharge.component';
import {DataPacksComponent} from './pages/personal/data-packs/data-packs.component';
import {VoicePacksComponent} from './pages/personal/voice-packs/voice-packs.component';
import {CustomerCareComponent} from './pages/personal/customer-care/customer-care.component';
import {SelfCareComponent} from './pages/personal/self-care/self-care.component';
import {SubFooterComponent} from './layout/sub-footer/sub-footer.component';
import {ProductAirtimeComponent} from './pages/products/product-airtime/product-airtime.component';
import {ProductInternetComponent} from './pages/products/product-internet/product-internet.component';
import {ProductComboComponent} from './pages/products/product-combo/product-combo.component';
import {ProductInternationalComponent} from './pages/products/product-international/product-international.component';

import {IddCallsComponent} from './pages/idd-packs/idd-calls/idd-calls.component';
import {IddRoamingComponent} from './pages/idd-packs/idd-roaming/idd-roaming.component';
import {SikaKokooComponent} from './pages/big-time-data/sika-kokoo/sika-kokoo.component';
import {NightBundleComponent} from './pages/big-time-data/night-bundle/night-bundle.component';
import {FamilyPackComponent} from './pages/big-time-data/family-pack/family-pack.component';

import {CorporateSocialComponent} from './pages/corporate-social/corporate-social.component';
import {NewsComponent} from './pages/news/news.component';
import {MediaCenterComponent} from './pages/media-center/media-center.component';
import {AirteltigoMoneyHomeComponent} from './pages/airteltigo-money-home/airteltigo-money-home.component';
import {DeviceComponent} from './pages/personal/device/device.component';
import {DeviveDetailComponent} from './pages/personal/devive-detail/devive-detail.component';
import {AgmCoreModule} from '@agm/core';
import {ManagedHostedServicesComponent} from './pages/business/managed-hosted-services/managed-hosted-services.component';
import {WanVpnComponent} from './pages/business/wan-vpn/wan-vpn.component';
import {IsdnComponent} from './pages/business/isdn/isdn.component';
import {SipTrunksComponent} from './pages/business/sip-trunks/sip-trunks.component';
import {SmeBusinessPlansComponent} from './pages/business/sme-business-plans/sme-business-plans.component';
import {CorporateBusinessPlansComponent} from './pages/business/corporate-business-plans/corporate-business-plans.component';
import {BusinessPrepaidComponent} from './pages/business/business-prepaid/business-prepaid.component';
import {BusinessBuildOwnPlanComponent} from './pages/business/business-build-own-plan/business-build-own-plan.component';
import {BulkMessagingComponent} from './pages/business/bulk-messaging/bulk-messaging.component';
import {UssdShortCodeComponent} from './pages/business/ussd-short-code/ussd-short-code.component';
import {TollfreeComponent} from './pages/business/tollfree/tollfree.component';
import {M2mIotComponent} from './pages/business/m2m-iot/m2m-iot.component';
import {SponsoredDataComponent} from './pages/business/sponsored-data/sponsored-data.component';
import {MobileAdvertisingComponent} from './pages/business/mobile-advertising/mobile-advertising.component';
import {CommonComponentsModule} from './pages/common.module';
import {HttpClientModule} from '@angular/common/http';
import {PrivacyPolicyInfoComponent} from "./pages/company-policies/privacy-policy/learn-more/privacy-policy-Info.component";


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    // FormatUrlPipe,
    ProfileComponent,
    VisionComponent,
    ManagementComponent,
    TerritoriesComponent,
    ProfileDetailComponent,
    ProductsComponent,
    IDDPacksComponent,
    CareersComponent,
    VacanciesComponent,
    VacancyDetailComponent,
    ProductAirtimeComponent,
    ProductInternetComponent,
    ProductComboComponent,
    ProductInternationalComponent,
    CorporateSocialComponent,
    NewsComponent,
    MediaCenterComponent,
    PrivacyPolicyInfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    NgbModule,
    OwlModule,
    MatToolbarModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyC6EKyIH9FDu7b-Gd94SH5y7TK-CMxkKuY'
    }),
    MatTabsModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatSelectModule,
    CommonComponentsModule,
    HttpClientModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
