import {Component, OnInit} from '@angular/core';
import AOS from 'aos';
import {HelperService} from './app-services/helper.service';
import {CrudService} from './app-services/crud.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(private helpService: HelperService, private crud: CrudService) {
    this.getLocalPageJSONInfo(false);
    this.getPagesList();
    this.getSubCategoryList();
  }

  // get's pages list
  private getPagesList(): void {
    this.crud.getData('web/page').subscribe((res: any) => {
      if (res.response_code === 200 && Array.isArray(res.response_data.data.page)) {
        if (res.response_data.data.page.length > 0) {
          this.helpService.emitPagesListInfo(res.response_data.data.page);
        }
        if (res.response_data.data.webfaq.length > 0) {
          this.helpService.emitFAQ(res.response_data.data.webfaq);
        }
      } else {
        this.getLocalPageJSONInfo(true);
        this.getLocalFAQJSONInfo();
      }
    }, error => {
      this.getLocalPageJSONInfo(true);
      this.getLocalFAQJSONInfo();
    });
  }

  // get's local pages list
  private getLocalPageJSONInfo(global: boolean): void {
    this.crud.getLocalJSONData('page.json').subscribe(res => {
      if (global) {
        this.helpService.emitPagesListInfo(res);
      } else {
        this.helpService.localPageJSON$ = res;
      }
    }, error => {
      this.helpService.emitPagesListInfo([]);
    });
  }

  // get's local pages list
  private getLocalFAQJSONInfo(): void {
    this.crud.getLocalJSONData('faq.json').subscribe(res => {
      console.log('LOCAL FAQ', res);
      this.helpService.emitFAQ(res);
    }, error => {
      this.helpService.emitFAQ([]);
    });
  }

  // get sub-category list
  private getSubCategoryList(): void {
    this.crud.getData('websitesubcategories').subscribe((res: any) => {
      if (res.response_code === 200 && res.response_data) {
        this.helpService.emitSubCategoryList(res.response_data);
      }
    }, error => {
      this.helpService.emitSubCategoryList(null);
    });
  }

  ngOnInit() {
    AOS.init();
  }

}
