import {Pipe, PipeTransform} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {SafeHtml} from '@angular/platform-browser';

@Pipe({
  name: 'safeHTML'
})
export class TermsConditionsPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {

  }

  transform(value): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
