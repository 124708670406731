import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './pages/home/home.component';
import {ProfileComponent} from './pages/about/profile/profile.component';
import {VisionComponent} from './pages/about/vision/vision.component';
import {ManagementComponent} from './pages/about/management/management.component';
import {TerritoriesComponent} from './pages/about/territories/territories.component';
import {ProfileDetailComponent} from './pages/about/profile-detail/profile-detail.component';
import {CareersComponent} from './pages/careers/careers.component';
import {VacanciesComponent} from './pages/vacancies/vacancies.component';
import {VacancyDetailComponent} from './pages/vacancy-detail/vacancy-detail.component';
import {ProductAirtimeComponent} from './pages/products/product-airtime/product-airtime.component';
import {ProductInternetComponent} from './pages/products/product-internet/product-internet.component';
import {ProductComboComponent} from './pages/products/product-combo/product-combo.component';
import {ProductInternationalComponent} from './pages/products/product-international/product-international.component';
import {PrivacyPolicyComponent} from './pages/company-policies/privacy-policy/privacy-policy.component';
import {TermsConditionsComponent} from './pages/company-policies/terms-conditions/terms-conditions.component';
import {DisclaimerComponent} from './pages/company-policies/disclaimer/disclaimer.component';
import {FaqComponent} from './pages/company-policies/faq/faq.component';
import {from} from 'rxjs';
import {NewPage1Component} from './pages/new-pages-template/new-page1/new-page1.component';
import {LearnMoreComponent} from './pages/company-policies/terms-conditions/learn-more/learn-more.component';
import {PrivacyPolicyInfoComponent} from "./pages/company-policies/privacy-policy/learn-more/privacy-policy-Info.component";


const routes: Routes = [
  // { path: '', component: HomeComponent },
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {path: 'home', component: HomeComponent},
  {path: 'profile', component: ProfileComponent},
  {path: 'vision', component: VisionComponent},
  {path: 'management', component: ManagementComponent},
  {path: 'territories', component: TerritoriesComponent},
  {path: 'profile-detail', component: ProfileDetailComponent},

  // FIXME: big time data routes
  {path: 'bigtime-data', loadChildren: './pages/big-time-data/big-time-data.module#BigTimeDataModule'},
  {path: 'careers', component: CareersComponent},
  {path: 'vacancies', component: VacanciesComponent},
  {path: 'vacancy-detail', component: VacancyDetailComponent},
  //FIXME:// Airteltigo money routes
  {
    path: 'airteltigo-money', loadChildren: './pages/airteltigo-money/airteltigo-money.module#AirteltigoMoneyModule'
  },

  // FIXME:// International calls routes
  {path: 'international', loadChildren: './pages/idd-packs/idd.module#IddModule'},
  {path: 'voice', loadChildren: './pages/voice-calls/voice-calls.module#VoiceCallsModule'},

  // FIXME: Personal module routes
  {path: 'personal', loadChildren: './pages/personal/personal.module#PersonalModule'},

  // FIXME:// business related routes
  {
    path: 'business',
    loadChildren: './pages/business/business.module#BusinessModule'
  },

  {path: 'VAS', loadChildren: './pages/VAS/vas.module#VasModule'},
  {path: 'product-airtime', component: ProductAirtimeComponent},
  {path: 'product-internet', component: ProductInternetComponent},
  {path: 'product-combo', component: ProductComboComponent},
  {path: 'product-international', component: ProductInternationalComponent},

  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'privacy-policy/:slug', component: PrivacyPolicyInfoComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: 'new-page-template', component: NewPage1Component},
  {path: 'learn-more/:slug', component: LearnMoreComponent},

  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'terms-conditions', component: TermsConditionsComponent},
  {path: 'disclaimer', component: DisclaimerComponent},
  {path: 'faq', loadChildren: './pages/company-policies/faq/faq.module#FaqModule'},
  {path: 'new-page-template', component: NewPage1Component},
  {path: '**', redirectTo: '/home'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
