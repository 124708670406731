import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { PagesListModel } from "../pages/models/page-info.model";
import { SubCategoryModel } from "../pages/models/sub-category.model";
import { FaqModel } from "../pages/models/faq.model";

@Injectable({
  providedIn: "root",
})
export class HelperService {
  private tabSubject = new BehaviorSubject<number>(0); // emits selected tab id
  public tabInfo = this.tabSubject.asObservable(); // subscribes to tab index

  private devicesSubject = new BehaviorSubject(null); // contains list of devices, emits data whenever called
  public deviceList = this.devicesSubject.asObservable(); // listens to device subject, subscribes to lastest data

  private managementSubject = new BehaviorSubject(null); // contains list of management, emits data whenever called
  public manageMentList = this.managementSubject.asObservable(); // listens to managemenet subject , subscride when any changes
  private vacancySubject = new BehaviorSubject(null);
  public vacancyList = this.vacancySubject.asObservable();
  private pagesListSubject = new BehaviorSubject<Array<PagesListModel>>([]); // emits pages info list;
  public pagesInfo = this.pagesListSubject.asObservable(); // listens to pages subject

  private subCategorySubject = new BehaviorSubject<Array<SubCategoryModel>>(
    null
  ); // contains list of sub-categories
  public subCategoryInfo = this.subCategorySubject.asObservable(); // listens to sub-category subject

  private faqSubject = new BehaviorSubject<Array<FaqModel>>([]); // emits pages info list;
  public faqInfo = this.faqSubject.asObservable(); // listens to pages subject

  public localPageJSON$: Array<PagesListModel> = []; // contains information of all the pages

  constructor() {}

  // takes browser scroll bar to top
  public scrollTop(): void {
    window.scrollTo(0, 0);
  }

  // emits tab index
  public emitTabIndex(index: number): void {
    this.tabSubject.next(index);
  }

  // emits devices list
  public emitDeviceList(data): void {
    this.devicesSubject.next(data);
  }

  // emit management list
  public emitManagementList(data): void {
    this.managementSubject.next(data);
  }

  // emit pages list info
  public emitPagesListInfo(list: Array<PagesListModel>): void {
    this.pagesListSubject.next(list);
  }

  // emits vacancy list
  public emitVacancyList(data): void {
    this.vacancySubject.next(data);
  }

  // emits sub-category list
  public emitSubCategoryList(data): void {
    this.subCategorySubject.next(data);
  }

  // opens web app
  public openWebsiteApp(): void {
    window.open("http://app.at.com.gh ", "_blank");
  }

  // emits faq list
  public emitFAQ(faq: Array<FaqModel>): void {
    this.faqSubject.next(faq);
  }

  // finds and returns the information of the particular page
  public getPageInformation(pageType) {
    const pageInfo = this.localPageJSON$.find(
      (page) => page.pageType === pageType
    );
    return pageInfo;
  }
}
