import {Component, OnInit} from '@angular/core';
import {PagesListModel} from '../../models/page-info.model';
import {HelperService} from '../../../app-services/helper.service';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  public pageInfo: PagesListModel;        // contains information about the page
  constructor(private helpserService: HelperService) {
  }

  ngOnInit() {
    this.helpserService.scrollTop();
    this.helpserService.pagesInfo.subscribe(pages => {
      const pageInfo = pages.find(page => page.pageType === 'Disclaimer page');
      this.pageInfo = pageInfo || this.getDefaultPageInfo();
    });
    //  Terms & Conditions page
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpserService.getPageInformation('Disclaimer page');
  }

}
