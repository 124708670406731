import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {HelperService} from '../../../app-services/helper.service';
import {PagesListModel} from '../../models/page-info.model';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public pageInfo: PagesListModel;        // contains information about the page

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
    this.helpService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Brief Profile');
      console.log(iddInfo);
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpService.getPageInformation('Brief Profile');
  }

}
