import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CrudService } from "src/app/app-services/crud.service";
import { Subject } from "rxjs";
import { HelperService } from "src/app/app-services/helper.service";
import { ThumbnailListModel } from "../models/thumbnails.model";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public homeDetail: any = {
    // this is used for collect home page details
    webbanner: [],
    websubbanner: [],
    device: [],
  };
  private isFromLocalJson = false; // this is for stop to route to device detail page
  public videoPath = "https://www.youtube.com/embed/GZJMuSCWFtE?rel=0"; // contains path of the video
  public thumbnails: Array<ThumbnailListModel> = []; // contains list of thumbnails
  public carouselBanner = {
    items: 1,
    dots: true,
    navigation: false,
    autoplay: true,
    loop: true,
    transition: "ease",
    autoplayTimeout: 6000,
    // slideSpeed: 12000,
    // interval: 12000
  };

  public carouselMulImgOption = {
    // items:8,
    dots: false,
    navigation: false,
    autoplay: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      768: {
        items: 1,
      },
      1024: {
        items: 2,
      },
      1292: {
        items: 3,
      },
      1570: {
        items: 4,
      },
    },
  }; // contains carousel options
  constructor(
    private crudService: CrudService,
    private router: Router,
    private helpService: HelperService
  ) {
    this.getHomePageDetail();
  }

  ngOnInit() {
    this.helpService.scrollTop();
  }

  // get home page detail
  private getHomePageDetail(): void {
    this.crudService.getData("web/home").subscribe(
      (res: any) => {
        if (res.response_code === 200) {
          this.homeDetail.webbanner = res.response_data.data.webbanner.filter(
            (list) => list.forPage === "home page"
          );
          this.homeDetail.websubbanner =
            res.response_data.data.websubbanner.filter(
              (list) => list.forPage === "home page"
            );
          this.homeDetail.device = res.response_data.data.device.slice(0, 6);
          this.thumbnails = res.response_data.data.thumbnail;
          if (this.homeDetail.device.length > 0) {
            this.helpService.emitDeviceList(this.homeDetail.device);
          }
        } else {
          this.isFromLocalJson = true;
          this.getHomeDataFromLocal();
        }
      },
      (error) => {
        this.isFromLocalJson = true;
        this.getHomeDataFromLocal();
      }
    );
  }

  // get Data from local json from assets
  private getHomeDataFromLocal(): void {
    this.crudService.getLocalJSONData("home.json").subscribe((res: any) => {
      if (res.response_code === 200) {
        this.homeDetail.webbanner = res.response_data.data.webbanner;
        this.homeDetail.websubbanner = res.response_data.data.websubbanner;
        this.homeDetail.device = res.response_data.data.device.filter(
          (list) => list.status === 1
        );
        this.thumbnails = res.response_data.data.thumbnail;
        if (this.homeDetail.device.length > 0) {
          this.helpService.emitDeviceList(this.homeDetail.device);
        }
      }
    });
  }

  // opens a webapp
  public openWebApp(banner): void {
    window.open(banner.buttonUrl, "_blank");
  }

  public onClickBannerImage(url) {
    window.open(url, "_blank");
  }

  // go to device detail
  public goToDeviceDetail(deviceName) {
    if (!this.isFromLocalJson) {
      this.router.navigate(["/personal/device-detail"], {
        queryParams: { name: deviceName },
      });
    }
  }

  // sends selected tab
  public sendSelectedTabIndex(index: number): void {
    sessionStorage.setItem("tab", index.toString());
    this.helpService.emitTabIndex(index);
    sessionStorage.setItem("tab", index.toString());
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }
}
