import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HelperService} from "../../app-services/helper.service";

@Component({
  selector: 'app-sub-footer',
  templateUrl: './sub-footer.component.html',
  styleUrls: ['./sub-footer.component.scss']
})
export class SubFooterComponent implements OnInit {

  constructor(private router: Router, private helpService: HelperService) {
  }

  ngOnInit() {
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }

}
