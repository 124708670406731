import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../../app-services/helper.service';
import {PagesListModel} from '../../models/page-info.model';
import {CrudService} from '../../../app-services/crud.service';
import {TermsConditionsModel} from '../../models/terms-conditions.model';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss']
})
export class TermsConditionsComponent implements OnInit {
  public pageInfo: PagesListModel;        // contains information about the page
  public termsAndConditionsList: Array<TermsConditionsModel> = [];      // contains list of terms and conditions

  constructor(private helpserService: HelperService, private crud: CrudService) {
    this.getTermsAndConditions();
  }

  ngOnInit() {
    this.helpserService.scrollTop();
    this.helpserService.pagesInfo.subscribe(pages => {
      const pageInfo = pages.find(page => page.pageType === 'Terms & Conditions page');
      this.pageInfo = pageInfo || this.getDefaultPageInfo();
    });
    //  Terms & Conditions page
  }

  // gets terms and conditions list
  private getTermsAndConditions(): void {
    this.crud.getData('terms').subscribe((res: any) => {
      this.termsAndConditionsList = res.response_code === 200 ? res.response_data : [];
    }, error => {
      this.termsAndConditionsList = [];
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpserService.getPageInformation('Terms & Conditions page');
  }

}
