import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class CrudService {
  constructor(private http: HttpClient) {}

  // modifies end point by appending base url
  private GENERATE_ENDPOINT = (url: string) => environment.API_ENDPOINT + url;

  // sends request to get data from server
  public getData(url: string): Observable<any> {
    return this.http.get(this.GENERATE_ENDPOINT(url));
  }

  // sends request to get data from server
  public saveData(url: string, data: any): Observable<any> {
    return this.http.post(this.GENERATE_ENDPOINT(url), data);
  }

  // sends request to get data from server
  public updateData(url: string, data: any): Observable<any> {
    return this.http.put(this.GENERATE_ENDPOINT(url), data);
  }

  // get's local data from assets
  public getLocalJSONData(path: string): Observable<any> {
    return this.http.get(`assets/json/${path}`);
  }
}
