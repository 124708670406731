import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-page1',
  templateUrl: './new-page1.component.html',
  styleUrls: ['./new-page1.component.scss']
})
export class NewPage1Component implements OnInit {
  constructor() {


  }

  ngOnInit() {
  }

  imgslider = [
    {
      'img': '../../../../assets/img/bg-img/career.png',

    },
    {
      'img': '../../../../assets/img/bg-img/data-pack-banner.jpg',

    },
    {
      'img': '../../../../assets/img/bg-img/fixed-services-banner.jpg',

    },
    {
      'img': '../../../../assets/img/bg-img/career.png',

    },

  ];
}
