import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CrudService} from 'src/app/app-services/crud.service';
import {HelperService} from 'src/app/app-services/helper.service';
import {PagesListModel} from '../../models/page-info.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.scss']
})
export class ManagementComponent implements OnInit {
  private isFromLocalJson = false; // is used for check data from local
  public managementList: Array<any>; // collects list of all managers
  public pageInfo: PagesListModel;        // contains information about the page

  constructor(private crudService: CrudService, private helperService: HelperService, private router: Router) {
    this.getManagementList();
  }

  ngOnInit() {
    this.helperService.scrollTop();
    this.helperService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Management');
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helperService.getPageInformation('Management');
  }

  // used for get list of all management
  public getManagementList(): void {
    this.crudService.getData('web/managenent').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.managementList = _.orderBy(res.response_data.data, ['orderBy'], ['asc']);
        this.helperService.emitManagementList(this.managementList);
      } else {
        this.isFromLocalJson = true;
        this.getManagementListFromLocalJson();
      }
    }, error => {
      this.isFromLocalJson = true;
      this.getManagementListFromLocalJson();
    });
  }

  // get management list from local json
  public getManagementListFromLocalJson(): void {
    this.crudService.getLocalJSONData('management.json').subscribe((res: any) => {
      if (res.response_code === 200) {
        this.managementList = res.response_data.data;
        this.helperService.emitManagementList(this.managementList);
      }
    });
  }

  // go to detail view of profile detail
  public onGoToDetail(managerName) {
    this.router.navigate(['/profile-detail'], {queryParams: {name: managerName}});
  }


}
