import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-idd-packs',
  templateUrl: './idd-packs.component.html',
  styleUrls: ['./idd-packs.component.scss']
})
export class IDDPacksComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
