import {Component, OnInit} from '@angular/core';
import {PagesListModel} from '../models/page-info.model';
import {HelperService} from '../../app-services/helper.service';
import {ActivatedRoute} from '@angular/router';
import {VanancyModel} from '../models/vanancy.model';
import {CrudService} from '../../app-services/crud.service';

@Component({
  selector: 'app-vacancy-detail',
  templateUrl: './vacancy-detail.component.html',
  styleUrls: ['./vacancy-detail.component.scss']
})
export class VacancyDetailComponent implements OnInit {
  public pageInfo: PagesListModel;         // contains information about the page
  public vacancyId: string = null;        // contains default vacancy id
  public jobDetails: VanancyModel;      // contains information about the job

  constructor(private helpService: HelperService, private route: ActivatedRoute, private crud: CrudService) {
    this.jobDetails = this.getDefaultJobValues();
    this.route.queryParams.subscribe(params => {
      this.vacancyId = params.id;
    });
  }

  // get'sjob values
  private getDefaultJobValues(): VanancyModel {
    return {
      createdAt: null,
      description: null,
      employmentType: null,
      expiryDate: null,
      jobFunction: null,
      jobTitle: null,
      keyResponsibility: null,
      location: null,
      qualification: null,
      _id: null
    };
  }

  ngOnInit() {
    this.helpService.scrollTop();
    this.getJobDetails();
    this.helpService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Vacancies page');
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // gets job details
  private getJobDetails(): void {
    this.crud.getData(`vacancy/web/${this.vacancyId}`).subscribe((res: any) => {
      this.jobDetails = res.response_code === 200 ? res.response_data : this.getDefaultJobValues();
    }, error => {
      this.jobDetails = this.getDefaultJobValues();
    });
  }

  // applies job
  public applyJob(): void {
    let id = document.createElement('a');
    id.href = `mailto:mailto:recruitment@airteltigo.com.gh?subject=${this.jobDetails.jobTitle},body=Hi i want to apply for this position`;
    id.click();
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpService.getPageInformation('Vacancies page');
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }

}
