import {Component, OnInit} from '@angular/core';
import {PagesListModel} from '../models/page-info.model';
import {HelperService} from '../../app-services/helper.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {
  public pageInfo: PagesListModel;         // contains information about the page

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
    this.helpService.pagesInfo.subscribe(list => {
      const iddInfo = list.find(page => page.pageType === 'Career page');
      this.pageInfo = iddInfo ? iddInfo : this.getDefaultPageInfo();
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpService.getPageInformation('Career page');
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }
}
