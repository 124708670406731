import {NgModule} from '@angular/core';
import {SubFooterComponent} from '../layout/sub-footer/sub-footer.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MatExpansionModule} from '@angular/material/expansion';
import {FormatUrlPipe} from './home/shared/sanitizer.pipe';
import {RouterModule} from '@angular/router';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TermsConditionsComponent} from './company-policies/terms-conditions/terms-conditions.component';
import {PrivacyPolicyComponent} from './company-policies/privacy-policy/privacy-policy.component';
import {DisclaimerComponent} from './company-policies/disclaimer/disclaimer.component';
import {FaqComponent} from './company-policies/faq/faq.component';
import {NewPage1Component} from './new-pages-template/new-page1/new-page1.component';
import {OwlModule} from 'ngx-owl-carousel';
import {LearnMoreComponent} from './company-policies/terms-conditions/learn-more/learn-more.component';
import {TermsConditionsPipe} from './company-policies/terms-conditions/terms-conditions.pipe';


@NgModule({
  imports: [CommonModule, RouterModule, FormsModule, NgbModule, MatExpansionModule, OwlModule],
  declarations: [SubFooterComponent, FormatUrlPipe, TermsConditionsComponent, PrivacyPolicyComponent, DisclaimerComponent, NewPage1Component, LearnMoreComponent, TermsConditionsPipe],
  exports: [SubFooterComponent, FormatUrlPipe, RouterModule, TermsConditionsPipe]
})
export class CommonComponentsModule {

}
