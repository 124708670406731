import {Component, OnInit} from '@angular/core';
import {HelperService} from '../../app-services/helper.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  constructor(private helpService: HelperService) {
  }

  ngOnInit() {
    this.helpService.scrollTop();
  }

}
