import {Component, OnInit} from '@angular/core';
import {PagesListModel} from '../../models/page-info.model';
import {HelperService} from '../../../app-services/helper.service';
import {CrudService} from "../../../app-services/crud.service";
import {TermsConditionsModel} from "../../models/terms-conditions.model";

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  public pageInfo: PagesListModel;        // contains information about the page
  public privacyPolicies: Array<TermsConditionsModel> = [];     // contains list of privacy policies

  constructor(private helpserService: HelperService, private crud: CrudService) {
    this.getPrivacyPoliciesList();
  }

  ngOnInit() {
    this.helpserService.scrollTop();
    this.helpserService.pagesInfo.subscribe(pages => {
      const pageInfo = pages.find(page => page.pageType === 'Privacy policy page');
      this.pageInfo = pageInfo || this.getDefaultPageInfo();
    });
    //  Terms & Conditions page
  }

  // get's list of privacy policies
  private getPrivacyPoliciesList(): void {
    this.crud.getData('privacy-policy').subscribe((res: any) => {
      this.privacyPolicies = res.response_code === 200 ? res.response_data : [];
    }, error => {
      this.privacyPolicies = [];
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helpserService.getPageInformation('Privacy policy page');
  }

}
