import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {TermsConditionsModel} from '../../../models/terms-conditions.model';
import {CrudService} from '../../../../app-services/crud.service';
import {HelperService} from '../../../../app-services/helper.service';
import {PagesListModel} from '../../../models/page-info.model';

@Component({
  selector: 'app-learn-more',
  templateUrl: './learn-more.component.html',
  styleUrls: ['./learn-more.component.scss']
})
export class LearnMoreComponent implements OnInit {
  public termsAndCondition: TermsConditionsModel;     // contains information about terms and condition
  public slug: string = null;     // contains slug from route
  public pageInfo: PagesListModel;        // contains information about the page

  constructor(private activatedRoute: ActivatedRoute, private crud: CrudService,private helperService:HelperService) {
    this.termsAndCondition = this.getDefaultTermsAndConditionsModel();
    this.activatedRoute.params.subscribe(params => {
      this.slug = params.slug;
      this.getTermsAndConditionInformation();
    });
  }

  ngOnInit() {
    this.helperService.scrollTop();
    this.helperService.pagesInfo.subscribe(pages => {
      const pageInfo = pages.find(page => page.pageType === 'Terms & Conditions page');
      this.pageInfo = pageInfo || this.getDefaultPageInfo();
    });
  }

  // gets default terms and conditions values
  private getDefaultTermsAndConditionsModel(): TermsConditionsModel {
    return {
      _id: null,
      title: null,
      shortDescription: null,
      description: null,
      createdAt: null,
      updatedAt: null,
      slug: null
    };
  }

  // sends request to get terms and condition information
  private getTermsAndConditionInformation(): void {
    this.crud.getData(`terms/${this.slug}`).subscribe((res: any) => {
      if (res.response_code === 200) {
        this.termsAndCondition = res.response_data;
      }
    });
  }

  // get's default page information
  private getDefaultPageInfo(): PagesListModel {
    return this.helperService.getPageInformation('Terms & Conditions page');
  }

}
