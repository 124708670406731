import { Component, OnInit } from "@angular/core";
import { HelperService } from "../../app-services/helper.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  public sidemenuActive = false;
  public profilemenuActive = false;
  public panelOpenState = false;
  public activeFade = false;

  public searchbar = false;
  public selectedIndex: number = 0; // contains index of selected tab
  public selectedField: string = "voice"; // contains name of selected field
  public imageUrl: string = "assets/img/at-logos/at-logo-sm.png"; // contains airteltigo logo

  constructor(private helpService: HelperService, private router: Router) {}

  ngOnInit() {
    this.helpService.tabInfo.subscribe((index) => {
      this.selectedIndex = index;
      if (sessionStorage.getItem("tab")) {
        this.selectedIndex = Number(sessionStorage.getItem("tab"));
        this.goToRespenctiveHome();
        // this.setAirtelTigoLogo();
      }
    });
  }

  // sets airteltigo logo
  public setAirtelTigoLogo(): void {
    switch (this.selectedIndex) {
      case 0:
        this.imageUrl = "assets/img/at-logos/at-logo-sm.png";
        break;
      case 1:
        this.imageUrl = "assets/img/at-logos/business.png";
        break;
      case 2:
        this.imageUrl = "assets/img/at-logos/money.png";
        break;
    }
  }

  // sends selected tab
  public sendSelectedTabIndex(index: number): void {
    this.selectedIndex = index;
    // this.setAirtelTigoLogo();
    sessionStorage.setItem("tab", index.toString());
  }

  // navigates to respective home page
  public goToRespenctiveHome(): void {
    switch (this.selectedIndex) {
      case 0:
        this.router.navigate(["home"]);
        break;
      case 1:
        this.router.navigate(["business/home"]);
        break;
      case 2:
        this.router.navigate(["airteltigo-money/home"]);
        break;
    }
  }

  // opens web app
  public openWebsiteApp(): void {
    this.helpService.openWebsiteApp();
  }

  openSidemenu() {
    this.sidemenuActive = !this.sidemenuActive;
    this.activeFade = false;
  }

  closeSidemenu() {
    this.sidemenuActive = false;
    this.activeFade = true;
  }

  openProfilemenu() {
    this.profilemenuActive = !this.profilemenuActive;
  }

  openSearchbar() {
    this.searchbar = !this.searchbar;
  }
}
